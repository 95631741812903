<template>
  <div
    class="container d-flex flex-column align-items-center justify-content-center"
  >
    <img src="@/assets/images/feedback/belinha_duvida.png" />
    <p class="title-patternOne text-primary">Ops! Página não encontrada!</p>
    <b-button type="button" variant="secondary" size="lg" @click="back()">Voltar</b-button>
  </div>
</template>

<script>
export default {
    methods: {
        back() {
            if (localStorage.getItem('access_token'))
                location.replace(`/verify-email/${localStorage.getItem('access_token')}`)
                else
                location.href = process.env.VUE_APP_ACCOUNT_LINK;
        }
    }
}
</script>

<style lang="scss" scoped>
.container {
    height: 100vh;
  img {
    width: 400px;
    @media (max-width: 500px) {
      width: 250px;
    }
  }
  p,
  .btn {
    margin-top: 2.5rem;
  }
  .btn {
    margin-bottom: 1rem;
  }
}
</style>